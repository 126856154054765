<ng-template #popover>
  <div *ngIf="response$ | async as response; else loading">
    <ng-container *ngIf="response.items.length > 0">
      <img
        alt=""
        [src]="response.brand.brandicon.src"
        style="float: right; margin: 0 0 5px 5px"
        *ngIf="response.brand.brandicon"
      />
      <p *ngFor="let item of response.items" [innerHTML]="item.nameHtml"></p>
      <br style="clear: both" />
    </ng-container>
  </div>
</ng-template>
<span *ngIf="brand$ | async as brand">
  <a [routerLink]="brand.catname">{{ brand.name }}</a>
  <span class="count"
    >({{ brand.itemsCount - brand.newItemsCount
    }}<span
      *ngIf="brand.newItemsCount > 0"
      [ngbPopover]="popover"
      triggers="mouseenter:mouseleave"
      container="body"
      popoverTitle="New {{ brand.name }} vehicles"
      i18n-popoverTitle
      >+{{ brand.newItemsCount }}</span
    >)</span
  >
</span>

<ng-template #loading>
  <div class="spinner-border" role="status"><span class="visually-hidden" i18n>Loading…</span></div>
</ng-template>
