<h4>
  <ng-container i18n>Brands</ng-container>
  <small><a routerLink="/brands" i18n>all</a></small>
</h4>

<ng-template #placeholder>
  <p class="index-brands placeholder-glow">
    <span class="placeholder me-1" [style.width]="item + 'rem'" *ngFor="let item of placeholderItems"></span>
  </p>
</ng-template>

<p class="index-brands" *ngIf="result$ | async as result; else placeholder">
  <app-index-brands-brand [brand]="brand" *ngFor="let brand of result.brands"></app-index-brands-brand>
  <ng-container *ngIf="result.more > 0">
    <ng-container i18n>… and</ng-container>&#xa0;<a routerLink="/brands" i18n="@@more-n-companies">{result.more, plural,
      one {more {{ result.more }} company}
      other {more {{ result.more }} companies}
    }</a>
  </ng-container>
</p>
