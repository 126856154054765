<ng-template #popover>
  <div *ngIf="response$ | async as response; else loading">
    <ng-container *ngIf="response.items.length > 0">
      <img
        alt=""
        [src]="response.brand.brandicon.src"
        style="float: right; margin: 0 0 5px 5px"
        *ngIf="response.brand.brandicon"
      />
      <p *ngFor="let item of response.items" [innerHTML]="item.nameHtml"></p>
      <br style="clear: both" />
    </ng-container>
  </div>
</ng-template>
<span *ngIf="factory$ | async as factory">
  <a [routerLink]="['/factories', factory.id]">{{ factory.name }}</a>
  <span class="count"
    >({{ factory.count - factory.newCount
    }}<span *ngIf="factory.newCount > 0" [ngbPopover]="popover" triggers="mouseenter:mouseleave" container="body"
      >+{{ factory.newCount }}</span
    >)</span
  >
</span>

<ng-template #loading>
  <div class="spinner-border" role="status"><span class="visually-hidden" i18n>Loading…</span></div>
</ng-template>
