<app-item-of-day class="d-block mb-4" [item$]="itemOfDayItem$" [user$]="itemOfDayUser$"></app-item-of-day>

<app-index-donate></app-index-donate>

<app-index-brands></app-index-brands>

<app-index-pictures></app-index-pictures>

<div class="row">
  <div class="col-md-6">
    <app-index-twins></app-index-twins>
  </div>
  <div class="col-md-6">
    <app-index-categories></app-index-categories>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <h4>
      <ng-container i18n>Mostly</ng-container> <small><a routerLink="/mosts" i18n>all</a></small>
    </h4>
    <p>
      <ng-container *ngFor="let most of mosts">
        <a [routerLink]="most.route">{{ most.name }}</a
        >,
      </ng-container>
      …
    </p>
    <app-markdown
      i18n-markdown
      markdown="Want to know which car was the fastest? Do you want to know how much the most powerful mining truck weighs? Come and find out."
    ></app-markdown>
  </div>

  <div class="col-md-6">
    <app-index-factories></app-index-factories>
  </div>
</div>

<div class="row mb-4">
  <div class="col-md-6">
    <h4>
      <ng-container i18n>Persons on photo</ng-container> <small><a routerLink="/persons" i18n>all</a></small>
    </h4>
    <p *ngIf="contentPersons$ | async as persons; else contentLoading">
      <ng-container *ngFor="let person of persons.items">
        <a [routerLink]="['/persons', person.id]">{{ person.name }}</a
        >,
      </ng-container>
      …
    </p>
    <ng-template #contentLoading>
      <div class="spinner-border" role="status"><span class="visually-hidden" i18n>Loading…</span></div>
    </ng-template>
  </div>

  <div class="col-md-6">
    <h4>
      <ng-container i18n>Authors</ng-container> <small><a routerLink="/persons/authors" i18n>all</a></small>
    </h4>
    <p *ngIf="authorPersons$ | async as persons; else authorLoading">
      <ng-container *ngFor="let person of persons.items">
        <a [routerLink]="['/persons', person.id]">{{ person.name }}</a
        >,
      </ng-container>
      …
    </p>
    <ng-template #authorLoading>
      <div class="spinner-border" role="status"><span class="visually-hidden" i18n>Loading…</span></div>
    </ng-template>
  </div>
</div>

<app-index-specs-cars></app-index-specs-cars>

<div>
  <h4 class="mb-4" i18n>Also</h4>
  <p><a routerLink="/mascots" i18n>Mascots</a> and <a routerLink="/cutaway/authors" i18n>Cutaway</a></p>
</div>
