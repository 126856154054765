<ng-container *ngIf="item">
  <span [innerHTML]="item.nameHTML"></span>
  <sup class="design-brand mx-2" *ngIf="item.design"
    ><ng-container i18n>designed by</ng-container>
    <a [routerLink]="item.design.route" [textContent]="item.design.name"></a>
  </sup>

  <ng-container *ngIf="item.produced as p">
    <small *ngIf="p.count > 1" class="ms-2">
      <span *ngIf="p.exactly" i18n="@@n-produced">{p.count, plural,
        one {Produced in {{ p.count }} copy}
        other {Produced in {{ p.count }} copies}
      }</span>
      <span *ngIf="!p.exactly" i18n="@@n-produced-about">{p.count, plural,
        one {Produced in about {{ p.count }} copy}
        other {Produced in about {{ p.count }} copies}
      }</span>
    </small>
    <small i18n *ngIf="p.count === 1" class="ms-2">Produced in a single copy</small>
  </ng-container>
</ng-container>
