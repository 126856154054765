<h4>
  <ng-container i18n>Categories</ng-container> <small><a routerLink="/category" i18n>all</a></small>
</h4>
<ng-template #loading>
  <div class="spinner-border" role="status"><span class="visually-hidden" i18n>Loading…</span></div>
</ng-template>
<p *ngIf="result$ | async as result; else loading">
  <app-index-categories-category
    [category]="category"
    *ngFor="let category of result.items"
  ></app-index-categories-category>
</p>
<app-markdown2 i18n>
  Do you know which cars helped to maintain law and order on the streets of world cities at different times? What cars
  did the Pope drive? Not? Then you are exactly in this section.
</app-markdown2>
