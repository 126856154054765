<div class="moderator-menu-container" *ngIf="items$ | async as items">
  <div class="btn-group" ngbDropdown placement="top-right">
    <button ngbDropdownToggle class="btn btn-secondary">
      <ng-container i18n>Moderator menu</ng-container>
      <span class="caret" aria-hidden="true"></span>
    </button>
    <div class="dropdown-menu-right" ngbDropdownMenu>
      <a
        [routerLink]="item.routerLink"
        [queryParams]="item.queryParams"
        class="dropdown-item"
        *ngFor="let item of items"
      >
        <i aria-hidden="true" [ngClass]="item.icon" *ngIf="item.icon"></i>
        {{ item.label }}
        <span class="badge rounded-pill text-bg-secondary" *ngIf="item.count" [textContent]="item.count"></span>
      </a>
      <div class="dropdown-divider"></div>
      <a routerLink="/moder" class="dropdown-item">
        <i class="bi bi-gear-fill" aria-hidden="true"></i>
        <ng-container i18n>Moderator page</ng-container>
      </a>
    </div>
  </div>
</div>
